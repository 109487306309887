// 头部登陆注册导航栏
<template>
  <div class="header_wrap">
    <div class="header_con">
      <div class="header_right">
        <span class="header-btn">
          <a :href="sellerUrl" target="_blank">
            {{ L['商家入驻'] }}
          </a>
        </span>
        <span @click="openLoingModal">{{ L['官方客服'] }}</span>
        <span @click="goToByPush('/article')">{{ L['服务中心'] }}</span>
        <div class="change_Language">
          <el-dropdown trigger="click" @command="handleLanguage">
            <span class="el-dropdown-link">
              <!-- 语言 -->
              {{ language }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="zh">中文</el-dropdown-item>
                <el-dropdown-item command="ru">Русский язык</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

      </div>
    </div>
    <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />

  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getCurrentInstance, ref, inject, onMounted } from "vue";
import { getCurLanguage } from '../utils/common'
import SldLoginModal from '@/components/loginModal'
export default {
  name: "NavTopBar",
  components: {
    SldLoginModal
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const L = proxy.$getCurLanguage();
    const memberInfo = store.state.memberInfo;
    const loginFlag = store.state.loginFlag;
    const router = useRouter();
    const configInfo = store.state.configInfo;
    const loginModalVisibleFlag = ref(false) //登录弹框是否显示，默认不显示
    const host = window.location.host
    const sellerUrl = ref('')
    if (host.indexOf('dzon.kz') > -1) {
      sellerUrl.value = 'https://seller.dzon.kz'
    } else if (host.indexOf('easybuykz.com')) {
      sellerUrl.value = 'https://seller.easybuykz.com'
    } else {
      sellerUrl.value = 'https://seller.dzon.kz'
    }
    //去注册
    const goRegister = () => {
      router.push({
        path: `/register`,
        query: {
          redirect: encodeURIComponent(window.location.href)
        }
      });
    };

    //关闭登录弹框
    const closeLoingModal = () => {
      loginModalVisibleFlag.value = false
    }

    //打开登录弹框
    const openLoingModal = (type) => {
      // loginModalVisibleFlag.value = true
      //已登录
      if (store.state.loginFlag) {
        // 去官方客服
        goToByPush('/service', { vid: 0 })

      } else {
        loginModalVisibleFlag.value = true
      }

    }
    //前往具体的页面，type为具体的页面地址，param为参数对象
    const goToByPush = (type, param = {}) => {
      if (type == "/service") {
        let chatInfo = {
          storeId: 0,
          vendorAvatar: configInfo.platform_customer_service_logo,
          storeName: configInfo.platform_customer_service_name,
          showData: {},
          source: "pc商城"
        };
        store.commit("saveChatBaseInfo", chatInfo);

        let newWin = router.resolve({
          path: type,
          query: param
        });

        window.open(newWin.href, "_blank");
        return;
      }

      router.push({
        path: type,
        query: param
      });
    };
    //退出登录
    const loginOut = () => {
      store.commit("clearAllData"); //清除数据
      store.commit("clearCartStorage");
      proxy.$socket.disconnect();
      goToByPush("/index", {});
    };
    const reload = inject('reload')
    // 切换语言
    const handleLanguage = (command) => {
      // console.log(command, "command")//下拉菜单里面的值
      // console.log(command)

      console.log(language.value)
      localStorage.setItem('languageSet', command)
      getCurLanguage()
      reload()
    }

    const language = ref('Русский язык')
    onMounted(() => {
      // console.log(configInfo);
      let languageTemp = localStorage.getItem('languageSet') || 'ru'
      if (languageTemp == "zh") {
        language.value = '中文'
      }
      if (languageTemp == "ru") {
        language.value = 'Русский язык'
      }
    })
    return {
      L,
      language,
      loginModalVisibleFlag,
      openLoingModal,
      closeLoingModal,
      goRegister,
      memberInfo,
      loginOut,
      loginFlag,
      goToByPush,
      configInfo,
      handleLanguage,
      sellerUrl
    };
  }
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: var(--color_main);
  }
}

.header_wrap {
  .header_con {
    width: 1320px;
    height: 32px;
    margin: 0 auto;

    .header_right {
      display: flex;
      align-items: center;
      justify-content: right;
      height: 32px;
      font-weight: 500;

      > span {
        margin-left: 24px;
        cursor: pointer;
      }

      .header-btn {
        // width: 73px;
        padding: 0 12px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eff0ff;
        color: #005cf6;
        border-radius: 16px;
        a {
          color: #005cf6;
        }
      }

      .change_Language {
        margin-left: 24px;
        cursor: pointer;
        line-height: 2;
      }
    }
  }
}
</style>